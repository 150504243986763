import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ChatOne from './components/ChatOne';
import ChatTwo from './components/ChatTwo';
import './index.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ChatOne />} />
        <Route path="/chat_two" element={<ChatTwo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App; 