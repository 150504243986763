import React, { useState, useRef, useEffect } from 'react';
import { FaPlay, FaPause } from 'react-icons/fa';

const ChatBubble = ({
  sender,
  text,
  question,
  image,
  audio,
  video,
  options,
  button,
  link,
  showProfile,
  onOptionSelect,
  onMediaEnd,
  setAwaitingAnswer,
  profileImage,
  folder,
}) => {
  const audioRef = useRef(null);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [progressAudio, setProgressAudio] = useState(0);

  const videoRef = useRef(null);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [showVideoControls, setShowVideoControls] = useState(false);
  const videoControlsTimeoutRef = useRef(null);

  const [optionsDisabled, setOptionsDisabled] = useState(false);

  // Gerenciamento do Áudio
  useEffect(() => {
    if (audio) {
      const playAudioWithDelay = setTimeout(() => {
        const playAttempt = audioRef.current.play();

        if (playAttempt !== undefined) {
          playAttempt
            .then(() => {
              setIsPlayingAudio(true);
            })
            .catch((error) => {
              console.error('Erro ao reproduzir o áudio automaticamente:', error);
              const retryPlay = () => {
                audioRef.current
                  .play()
                  .then(() => setIsPlayingAudio(true))
                  .catch(e => console.error('Erro na segunda tentativa:', e));
              };
              setTimeout(retryPlay, 1000);
            });
        }
      }, 300);

      audioRef.current.onended = () => {
        setIsPlayingAudio(false);
        if (onMediaEnd) onMediaEnd();
      };

      return () => clearTimeout(playAudioWithDelay);
    }
  }, [audio, onMediaEnd]);

  const togglePlayPauseAudio = () => {
    if (!audioRef.current) return;

    if (isPlayingAudio) {
      audioRef.current.pause();
    } else {
      audioRef.current
        .play()
        .then(() => setIsPlayingAudio(true))
        .catch((error) => {
          console.error('Erro ao reproduzir o áudio:', error);
        });
    }
    setIsPlayingAudio(!isPlayingAudio);
  };

  const handleTimeUpdateAudio = () => {
    if (audioRef.current) {
      const currentProgress =
        (audioRef.current.currentTime / audioRef.current.duration) * 100;
      setProgressAudio(isNaN(currentProgress) ? 0 : currentProgress);
    }
  };

  const handleProgressClickAudio = (e) => {
    if (!audioRef.current) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const clickPosition = (e.clientX - rect.left) / rect.width;
    audioRef.current.currentTime = clickPosition * audioRef.current.duration;
  };

  // Gerenciamento do Vídeo
  const togglePlayPauseVideo = () => {
    if (!videoRef.current) return;

    if (isPlayingVideo) {
      videoRef.current.pause();
    } else {
      videoRef.current
        .play()
        .then(() => setIsPlayingVideo(true))
        .catch((error) => {
          console.error('Erro ao reproduzir o vídeo:', error);
        });
    }
    setIsPlayingVideo(!isPlayingVideo);
    showTemporaryVideoControls();
  };

  const showTemporaryVideoControls = () => {
    setShowVideoControls(true);
    if (videoControlsTimeoutRef.current) {
      clearTimeout(videoControlsTimeoutRef.current);
    }
    videoControlsTimeoutRef.current = setTimeout(() => {
      setShowVideoControls(false);
    }, 2000);
  };

  useEffect(() => {
    return () => {
      if (videoControlsTimeoutRef.current) {
        clearTimeout(videoControlsTimeoutRef.current);
      }
    };
  }, []);

  // Autoplay do Vídeo com Atributos Adequados
  useEffect(() => {
    if (video && videoRef.current) {
      // Tentativa de reprodução automática
      const playVideo = async () => {
        try {
          await videoRef.current.play();
          setIsPlayingVideo(true);
        } catch (error) {
          console.error('Erro ao reproduzir o vídeo automaticamente:', error);
        }
      };

      playVideo();

      videoRef.current.onended = () => {
        setIsPlayingVideo(false);
        if (onMediaEnd) onMediaEnd();
      };
    }
  }, [video, onMediaEnd]);

  // Atualização de Estado com useEffect para evitar chamadas durante a renderização
  useEffect(() => {
    if (question) {
      setAwaitingAnswer(true);
    } else {
      setAwaitingAnswer(false);
    }
  }, [question, setAwaitingAnswer]);

  const handleOptionClick = (value) => {
    if (optionsDisabled) return;
    setOptionsDisabled(true);
    onOptionSelect(value);
  };

  return (
    <div
      className={`flex ${
        sender === 'me' ? 'justify-end' : 'justify-start cont-then-profile-picture'
      } mb-2 opacity-0 animate-fadeIn`}
    >
      {sender === 'them' ? (
        showProfile ? (
          <img
            src={profileImage}
            alt="Profile"
            className="w-8 h-8 mr-2 rounded-full object-cover then-profile-picture"
          />
        ) : (
          <div className="w-8 h-8 mr-2" />
        )
      ) : null}
      <div
        className={`relative max-w-xs px-4 py-2 ${
          sender === 'me' ? 'my-message' : 'their-message'
        }`}
      >
        {/* Renderização para o tipo 'question' */}
        {question ? (
          <div className="flex flex-col space-y-2">
            {/* Removido a chamada direta de setAwaitingAnswer */}
            <p>{question}</p>
            {/* Removido o formulário aqui */}
          </div>
        ) : image ? (
          <>
            {/* Removido a chamada direta de setAwaitingAnswer */}
            <img
              src={`${process.env.PUBLIC_URL}/${folder}/img/${image}`}
              alt="Enviada pelo contato"
              className="rounded-lg max-w-full h-auto"
            />
          </>
        ) : audio ? (
          <>
            {/* Removido a chamada direta de setAwaitingAnswer */}
            <div className="custom-audio-player flex items-center">
              <audio
                ref={audioRef}
                src={`${process.env.PUBLIC_URL}/${folder}/sounds/${audio}`}
                onTimeUpdate={handleTimeUpdateAudio}
              />
              <button
                onClick={togglePlayPauseAudio}
                className="play-pause-button mr-2 focus:outline-none"
              >
                {isPlayingAudio ? <FaPause /> : <FaPlay />}
              </button>
              <div
                className="progress-bar-container flex-grow h-2 bg-button rounded-full cursor-pointer"
                onClick={handleProgressClickAudio}
              >
                <div
                  className="progress-bar h-full bg-button rounded-full"
                  style={{ width: `${progressAudio}%` }}
                ></div>
              </div>
            </div>
          </>
        ) : video ? (
          <>
            {/* Removido a chamada direta de setAwaitingAnswer */}
            <div className="relative">
              <video
                ref={videoRef}
                src={`${process.env.PUBLIC_URL}/${folder}/vid/${video}`}
                className="rounded-lg max-w-full h-auto cursor-pointer"
                onClick={togglePlayPauseVideo}
                onPlay={() => {
                  setIsPlayingVideo(true);
                  showTemporaryVideoControls();
                }}
                onPause={() => {
                  setIsPlayingVideo(false);
                  showTemporaryVideoControls();
                }}
                autoPlay
                muted
                playsInline
              >
                Seu navegador não suporta o elemento de vídeo.
              </video>
              {showVideoControls && (
                <button
                  onClick={togglePlayPauseVideo}
                  className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300"
                >
                  {isPlayingVideo ? (
                    <FaPause className="text-white text-3xl" />
                  ) : (
                    <FaPlay className="text-white text-3xl" />
                  )}
                </button>
              )}
            </div>
          </>
        ) : options ? (
          <div className="flex flex-col space-y-2">
            {/* Removido a chamada direta de setAwaitingAnswer */}
            <p>{text}</p>
            <div className="">
              {options.map((option, idx) => (
                <button
                  key={idx}
                  onClick={() => handleOptionClick(option.value)}
                  className={`px-4 py-2 w-full btn-message mb-2 text-white rounded-lg focus:outline-none ${
                    optionsDisabled
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                  disabled={optionsDisabled}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>
        ) : button ? (
          <>
            {/* Removido a chamada direta de setAwaitingAnswer */}
            <div className="flex flex-col space-y-2">
              <p className="mb-2">{text}</p>
              <a href={link} className="btn-message text-white w-full p-2 rounded-lg">{button}</a>
            </div>
          </>
        ) : text ? (
          <>
            {/* Removido a chamada direta de setAwaitingAnswer */}
            {text}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ChatBubble;
