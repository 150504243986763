import React, { useState, useRef, useEffect } from 'react';
import {
  FaPaperPlane,
  FaPhone,
  FaVideo,
  FaArrowLeft,
} from 'react-icons/fa';
import { HiDotsVertical } from 'react-icons/hi';
import { MdVerified } from 'react-icons/md';
import ChatBubble from './ChatBubble';

const initialMessages = [
  {
    id: 1,
    sender: 'them',
    text: 'Oii tudo bem?',
    delay: 2000,
  },
  {
    id: 1,
    sender: 'them',
    audio: 'audio_1.mp3',
    delay: 5000,
  },
  {
    id: 2,
    sender: 'them',
    audio: 'audio_2.mp3',
    delay: 5000,
  },
  {
    id: 3,
    sender: 'them',
    text: 'Prazer, o meu nome é Thayane, tenho 19 aninhos, trabalho como atriz e criadora de conteúdo adulto, e moro em {cidade}',
    delay: 10000,
  },
  {
    id: 4,
    sender: 'them',
    question: 'E você? Qual é o seu nome? Onde você mora?',
    delay: 5000,
  },
  {
    id: 5,
    sender: 'them',
    question: 'Perfeito, amor! Eu posso te mandar um videozinho que acabei de gravar no banho?',
    delay: 5000,
  },
  {
    id: 6,
    sender: 'them',
    video: 'previa.mp4',
    delay: 8000,
  },
  {
    id: 7,
    sender: 'them',
    question: 'Gostou, vida? De 0 a 10, qual é a nota que eu mereço?',
    delay: 18000,
  },
  {
    id: 8,
    sender: 'them',
    audio: 'audio_3.mp3',
    delay: 30000,
  },
  {
    id: 9,
    sender: 'them',
    text: 'Você teria interesse em mais conteúdos como esse? Chamadas ao vivo; Fotos e vídeos exclusivos; Sorteios; Meu número pessoal; Encontro comigo; e muito mais.',
    options: [
      { label: 'Sim, tenho interesse!', value: 'sim' },
      { label: 'Não!', value: 'nao' },
    ],
    delay: 2000,
  },
];

const simMessages = [
  {
    id: 9,
    sender: 'them',
    text: 'Perfeito! Já que você se interessou, eu tenho uns conteúdinhos mais safadinhos, exclusivos e picantes.',
    delay: 5000,
  },
  {
    id: 10,
    sender: 'them',
    text: 'Eu vou te passar o meu WhatsApp pessoal para conversarmos melhor sobre isso, ok?',
    delay: 2000,
  },
  {
    id: 11,
    sender: 'them',
    text: 'Clica aqui 👇',
    button: 'Acessar meu WhatsApp pessoal',
    link: 'google.com',
    delay: 2000,
  },
];

const naoMessages = [
  {
    id: 11,
    sender: 'them',
    text: 'Que pena, vida! Já que você não se interessou pelos meus conteúdinhos exclusivos, eu vou liberar o seu acesso ao meu grupo grátis, ok?',
    delay: 6000,
  },
  {
    id: 12,
    sender: 'them',
    text: 'Clica aqui 👇',
    button: 'Acessar meu grupo grátis',
    link: 'google.com',
    delay: 2000,
  },
];

const folder = 'chat_2';

const profileImage = `${process.env.PUBLIC_URL}/${folder}/img/Foto_de_perfil.jpg`;

const ChatTwo = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [questionAnswer, setQuestionAnswer] = useState('');

  // Alteração: isTyping agora é uma string em vez de booleano
  const [isTyping, setIsTyping] = useState('');

  const messagesEndRef = useRef(null);
  const hasMounted = useRef(false);
  const [userCity, setUserCity] = useState('');
  const [userName, setUserName] = useState(''); // Estado para armazenar o nome do usuário

  const messageSound = useRef(
    new Audio(`${process.env.PUBLIC_URL}/sounds/message.mp3`)
  );

  const prevMessagesRef = useRef([]);

  const [mediaEndCallback, setMediaEndCallback] = useState(null);
  const [awaitingAnswer, setAwaitingAnswer] = useState(false);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        setUserCity(data.city);
      } catch (error) {
        console.error('Erro ao buscar localização:', error);
        setUserCity('sua cidade');
      }
    };

    fetchLocation();
  }, []);

  useEffect(() => {
    if (hasMounted.current || !userCity) return;

    const processMessages = async (messageQueue) => {
      let currentUserName = userName; // Variável local para armazenar o nome do usuário

      for (let msg of messageQueue) {
        if (msg.sender === 'them') {
          // Definir status de digitação
          if (msg.audio) {
            setIsTyping('Gravando Áudio...');
          } else if (msg.video) {
            setIsTyping('Enviando vídeo...');
          } else if (msg.image) {
            setIsTyping('Enviando Imagem...');
          } else {
            setIsTyping('Digitando...');
          }
          await delay(msg.delay);

          // Se for uma mensagem com pergunta, esperar a resposta
          if (msg.question) {
            setMessages((prevMessages) => [...prevMessages, msg]);
            setIsTyping('');

            // Espera a resposta do usuário via Promise
            const answer = await new Promise((resolve) => {
              setMediaEndCallback(() => (answer) => {
                resolve(answer);
                setMediaEndCallback(null);
              });
            });

            // Atualiza o nome do usuário e a variável local
            if (msg.question === 'Qual é o seu nome?') {
              setUserName(answer);
              currentUserName = answer; // Atualiza a variável local
            }

            setAwaitingAnswer(false);
            continue;
          }

          let messageText = msg.text || '';

          // Substituir placeholders
          if (messageText.includes('{cidade}')) {
            messageText = messageText.replace('{cidade}', userCity);
          }
          if (messageText.includes('{nome}')) {
            messageText = messageText.replace('{nome}', (currentUserName ? currentUserName.split(' ')[0].charAt(0).toUpperCase() + currentUserName.split(' ')[0].slice(1) : 'Usuário'));
          }

          setMessages((prevMessages) => [...prevMessages, { ...msg, text: messageText }]);
          setIsTyping('');

          if (msg.audio || msg.video || msg.image) {
            await new Promise((resolve) => {
              setMediaEndCallback(() => {
                resolve();
                setMediaEndCallback(null);
              });
            });
          }
          await delay(msg.delay);
        } else {
          setMessages((prevMessages) => [...prevMessages, msg]);
        }
      }
    };

    processMessages(initialMessages);
    hasMounted.current = true;
  }, [userCity, userName]); // Dependências incluem userName para atualizar currentUserName

  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping]);

  useEffect(() => {
    const prevMessages = prevMessagesRef.current;
    if (messages.length > prevMessages.length) {
      const newMessage = messages[messages.length - 1];
      if (newMessage.sender === 'them') {
        messageSound.current.currentTime = 0;
        messageSound.current
          .play()
          .catch((error) => {
            console.error('Erro ao reproduzir o áudio:', error);
          });
      }
    }
    prevMessagesRef.current = messages;
  }, [messages]);

  const enqueueMessages = async (sequence) => {
    let currentUserName = userName; // Variável local para manter o nome atualizado

    for (let msg of sequence) {
      if (msg.sender === 'them') {
        // Definir status de digitação
        if (msg.audio) {
          setIsTyping('Gravando Áudio...');
        } else if (msg.video) {
          setIsTyping('Enviando vídeo...');
        } else if (msg.image) {
          setIsTyping('Enviando Imagem...');
        } else {
          setIsTyping('Digitando...');
        }
        await delay(msg.delay);

        let messageText = msg.text || '';

        // Substituir placeholders
        if (messageText.includes('{cidade}')) {
          messageText = messageText.replace('{cidade}', userCity);
        }
        if (messageText.includes('{nome}')) {
          messageText = messageText.replace('{nome}', currentUserName || 'usuário');
        }

        setMessages((prevMessages) => [...prevMessages, { ...msg, text: messageText }]);
        setIsTyping('');

        if (msg.audio || msg.video || msg.image) {
          await new Promise((resolve) => {
            setMediaEndCallback(() => {
              resolve();
              setMediaEndCallback(null);
            });
          });
        }
        await delay(msg.delay);
      } else {
        setMessages((prevMessages) => [...prevMessages, msg]);
      }
    }
  };

  const handleOptionSelect = async (value) => {
    const userMessage = {
      id: messages.length + 1,
      sender: 'me',
      text: value === 'sim' ? 'Sim' : 'Não',
      delay: 500,
    };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    await delay(500);

    if (value === 'sim') {
      await enqueueMessages(simMessages);
    } else {
      await enqueueMessages(naoMessages);
    }
  };

  const handlePerguntaSubmit = async (e) => {
    e.preventDefault();
    const trimmedInput = questionAnswer.trim();
    if (trimmedInput === '') return;

    setAwaitingAnswer(false);

    const userMessage = {
      id: messages.length + 1,
      sender: 'me',
      text: trimmedInput,
      delay: 500,
    };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    // Chama o callback com a resposta do usuário
    if (mediaEndCallback) {
      mediaEndCallback(trimmedInput);
    }

    setQuestionAnswer(''); // Limpar a entrada
  };

  const handleSendMessage = () => {
    if (newMessage.trim() === '') return;

    const nextId = messages.length ? messages[messages.length - 1].id + 1 : 1;
    const message = { id: nextId, sender: 'me', text: newMessage.trim() };
    setMessages([...messages, message]);
    setNewMessage('');
    setAwaitingAnswer(false);

    // Definir o status como "Digitando..." para mensagens de texto enviadas pelo usuário
    setIsTyping('Digitando...');
    setTimeout(() => {
      const responseId = nextId + 1;
      let response;
      if (responseId === initialMessages.length + 1) {
        response = {
          id: responseId,
          sender: 'them',
          image: 'paisagem.png',
          delay: 2000,
        };
      } else if (responseId === initialMessages.length + 2) {
        response = {
          id: responseId,
          sender: 'them',
          video: 'video.mp4',
          delay: 2000,
        };
      } else {
        response = {
          id: responseId,
          sender: 'them',
          text: 'Entendi! Fique à vontade para conversar mais.',
          delay: 2000,
        };
      }

      setMessages((prevMessages) => [...prevMessages, response]);
      setIsTyping('');
    }, 5000);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (awaitingAnswer) {
        handlePerguntaSubmit(e);
        setAwaitingAnswer(false);
      } else {
        handleSendMessage();
      }
    }
  };

  const handleAnswerChange = (e) => {
    setQuestionAnswer(e.target.value);
  };

  const handleMainInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const shouldShowProfile = (index) => {
    const currentMessage = messages[index];
    if (currentMessage.sender !== 'them') return false;

    const nextMessage = messages[index + 1];
    return !nextMessage || nextMessage.sender !== 'them';
  };

  const handleBack = () => {
    console.log('Voltar');
  };

  const handleCall = () => {
    console.log('Iniciar chamada');
  };

  const handleVideoCall = () => {
    console.log('Iniciar video chamada');
  };

  return (
    <div className="flex flex-col cont-messages">
      {/* Cabeçalho do Chat */}
      <div className="header-chat text-white flex items-center p-4 fixed top-0 w-full z-10 h-16">
        <button onClick={handleBack} className="mr-4 focus:outline-none">
          <FaArrowLeft size={20} />
        </button>

        <div className="flex-1 flex items-center">
          <img
            src={profileImage}
            alt="Profile"
            className="w-10 h-10 mr-4 rounded-full object-cover"
          />
          <div>
            <div className="flex items-center justify-start">
              <span className="font-bold text-lg">Thayane</span>{' '}
              <MdVerified color="lightgreen" size={20} className="ml-2" />
            </div>
            {isTyping ? (
              <span className="block text-sm text-gray-200">
                {isTyping}
              </span>
            ) : (
              <span className="block text-sm text-gray-200">Online</span>
            )}
          </div>
        </div>

        <div className="flex space-x-4">
          <button className="focus:outline-none" onClick={handleVideoCall}>
            <FaVideo size={20} />
          </button>
          <button className="focus:outline-none" onClick={handleCall}>
            <FaPhone size={20} />
          </button>
          <button className="focus:outline-none">
            <HiDotsVertical size={20} />
          </button>
        </div>
      </div>

      {/* Área de Mensagens */}
      <div className={`flex-grow overflow-y-auto mt-16 mb-16 p-4`}>
        {messages.map((msg, index) => (
          <ChatBubble
            key={msg.id}
            sender={msg.sender}
            text={msg.text}
            image={msg.image}
            audio={msg.audio}
            question={msg.question}
            video={msg.video}
            options={msg.options}
            button={msg.button}
            link={msg.link}
            showProfile={shouldShowProfile(index)}
            onOptionSelect={handleOptionSelect}
            setAwaitingAnswer={setAwaitingAnswer}
            onMediaEnd={index === messages.length - 1 ? mediaEndCallback : null}
            profileImage={profileImage}
            userName={userName}
            folder={folder}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>

        <div className="fixed bottom-0 w-full p-4 flex items-center">
          <form onSubmit={handlePerguntaSubmit} className="flex w-full">
            <input
              type="text"
              value={questionAnswer}
              onChange={handleAnswerChange}
              placeholder="Digite sua resposta..."
              className="flex-grow bg-white p-2 border rounded-full mr-2 focus:outline-none"
              disabled={!awaitingAnswer}
            />
            <button
              type="submit"
              className="p-3 send-message-button text-white rounded-full flex items-center justify-center focus:outline-none"
              disabled={!awaitingAnswer}
            >
              <FaPaperPlane />
            </button>
          </form>
        </div>
    </div>
  );
};

export default ChatTwo;
